import request from "@/core/services/axios";
// import qs from "qs";

export function fetchList(query) {
  return request({
    url: "/exam/exampaperquestion/list",
    method: "get",
    params: query,
  });
}

export function getallsubjectquestiontype(query) {
  return request({
    url: "/exam/exampaper/getallsubjectquestiontype",
    method: "get",
    params: query,
  });
}

export function knowledgeList(id) {
  return request({
    url: "/exam/examquestion/search/knowledge/" + id,
    method: "get",
  });
}
// /search/training/
export function trainingList(id, page, examPaperId) {
  return request({
    url:
      "/exam/examquestion/search/training/" +
      id +
      "/" +
      page +
      "/" +
      examPaperId,
    method: "get",
  });
}

// 获取试题列表
export function postTrainingList(id, data) {
  return request({
    url: "/exam/examquestion/search/training/" + id,
    method: "post",
    data: data,
  });
}

// 修改试题题干
export function updateoptquestion(data) {
  return request({
    url: "/exam/examquestion/updateoptquestion",
    method: "post",
    data: data,
  });
}

// 是否自动过审
export function passTraining(data) {
  return request({
    url: "/resource/exampaperquestion/pass/training",
    method: "post",
    data: data,
  });
}

// 修改试题题干 新
export function updateoptquestionV2(data) {
  return request({
    url: "/exam/examquestion/updateoptquestion/v2",
    method: "post",
    data: data,
  });
}

// 获取审核进度与审核老师
export function trainingExamine(data) {
  return request({
    url: "/resource/exampaperteacherreview/training/examine",
    method: "get",
    params: data,
  });
}

export function exampaperquestion(id) {
  return request({
    url: "/exam/examquestion/" + id,
    method: "get",
  });
}

export function trainings(id, query) {
  return request({
    url: "/exam/exampaperquestion/trainings/" + id,
    method: "get",
    params: query,
  });
}
// 获取变式题
export function trainingsV2(id, query) {
  return request({
    url: "/exam/exampaperquestion/trainings/v2/" + id,
    method: "get",
    params: query,
  });
}

export function examRandom(obj) {
  return request({
    url: "exam/examquestion/random/training",
    method: "get",
    params: obj,
  });
}

export function clearKnowledge(obj) {
  return request({
    url: "/exam/exampaperquestion/clear/knowledge",
    method: "delete",
    params: obj,
  });
}

export function getList(id, number) {
  let data = {
    quesKeyword: number,
  };
  return request({
    url: "/exam/examquestion/recommend/knowledge/" + id,
    method: "post",
    data: data,
  });
}

export function getTraining(id, number) {
  return request({
    url: "exam/examquestion/refresh/training/" + id + "/" + number,
    method: "get",
  });
}

export function getRecommend(id, number, examPaperId) {
  let data = {
    quesKeyword: number,
  };
  return request({
    url: "exam/examquestion/recommend/training/" + id + "/" + examPaperId,
    method: "post",
    data: data,
  });
}

export function putObj(obj) {
  return request({
    url: "/exam/examquestion",
    method: "put",
    data: obj,
  });
}

export function putObjBatch(obj) {
  return request({
    url: "/exam/examquestion/batch",
    method: "put",
    data: obj,
  });
}

export function putObjExampaper(obj) {
  return request({
    url: "/exam/exampaper",
    method: "put",
    data: obj,
  });
}
// 获取试卷信息
export function getExampaper(id) {
  return request({
    url: "/exam/exampaper/" + id,
    method: "get",
  });
}

// 回显审题老师
export function exampaperteacherreviewList(obj) {
  return request({
    url: "/resource/exampaperteacherreview/list",
    method: "get",
    params: obj,
  });
}

export function exampaperquestionBatch(obj) {
  return request({
    url: "/exam/exampaperquestion/batch",
    method: "put",
    data: obj,
  });
}

export function putPaperQuestionObj(obj) {
  return request({
    url: "/exam/exampaperquestion",
    method: "put",
    data: obj,
  });
}

export function getQuestionPoint(obj) {
  return request({
    url: "/exam/exampaper/getquestionpoint",
    method: "post",
    data: obj,
  });
}

export function lockTraining(obj) {
  return request({
    url: "/exam/exampaperquestion/lockTraining",
    method: "post",
    data: obj,
  });
}
// 补充试题（todo）
export function supplementTraining(obj) {
  return request({
    url: "/resource/exampaperquestion/supplement/training",
    method: "post",
    data: obj,
  });
}

export function unlockTraining(obj) {
  return request({
    url: "/exam/exampaperquestion/unlockTraining",
    method: "post",
    data: obj,
  });
}

export function trainingExtend(obj) {
  return request({
    url: "/exam/exampaperquestion/training/extend",
    method: "post",
    data: obj,
  });
}
// 审题统计
export function examineStatistics(obj) {
  return request({
    url: "/resource/exampaper/examine/statistics",
    method: "post",
    data: obj,
  });
}

export function exampaperuestionList(obj) {
  return request({
    url: "/exam/exampaperquestion/list",
    method: "get",
    params: obj,
  });
}
// 审题权限
export function examineAuthority(obj) {
  return request({
    url: "/resource/exampaper/examine/authority",
    method: "get",
    params: obj,
  });
}
// 获取审题列表
export function examineQuestions(obj) {
  return request({
    url: "/resource/exampaper/examine/questions",
    method: "get",
    params: obj,
  });
}
// 保存审题教师
export function exampaperteacherreviewSave(obj) {
  return request({
    url: "/resource/exampaperteacherreview/save",
    method: "post",
    data: obj,
  });
}
// 设置审题时间
export function trainingTime(obj) {
  return request({
    url: "/resource/exampaperteacherreview/training/time",
    method: "post",
    data: obj,
  });
}
// 设置审题时间
export function saveResult(obj) {
  return request({
    url: "/resource/exampaperteacherreview/save/result",
    method: "post",
    data: obj,
  });
}
export function exampaperquestionExtend(id) {
  return request({
    url: "/exam/exampaperquestion/extend/" + id,
    method: "get",
    // params: obj,
  });
}

// 获取当前试题的变式训练题
export function questionExtendV2(id) {
  return request({
    url: "/exam/exampaperquestion/extend/v2/" + id,
    method: "get",
    // params: obj,
  });
}
