// 判断分数  大于一位小数 只返回1位小数
export const formatToSingleDecimal = (num, key) => {
  // 检查是否已经是单个小数位数
  if (typeof num === "number" && !Number.isInteger(num)) {
    const decimalPart = num.toString().split(".")[1];
    if (decimalPart && decimalPart.length > 1) {
      // 超过一位小数，格式化为只保留一位小数
      return parseFloat(num.toFixed(key));
    }
  }
  // 如果是整数或者已经是一位小数，直接返回
  return num;
};
