/* eslint-disable no-unused-vars */
const cssStyle =
  "@font-face{font-family:'TimesNewRoman';src:url('https://static.wtjy.com/webFont/NewRoman.ttf');}#initQuestion .question-cnt-box{font-family:'TimesNewRoman',宋体,STSong;letter-spacing:0px;line-height:2;overflow:hidden;display:inline-block;}#initQuestion .question-cnt-box .quizPutTag{border-bottom:1px solid #333333;}#initQuestion .question-cnt-box .pt1,#initQuestion .question-cnt-box .pt2{padding:0;line-height:unset;overflow:unset;}#initQuestion .question-cnt-box label{margin-bottom:0;}#initQuestion p{margin-bottom:0;}#initQuestion .X5,#initQuestion .wtTable{border-collapse:collapse;}#initQuestion .X5 th,#initQuestion .X5 td,#initQuestion .wtTable th,#initQuestion .wtTable td{border:1px solid #666666 !important;padding:0 8px;}#initQuestion .question-science{line-height:1.5;}#initQuestion .question-item-box span{white-space:break-spaces;}";
// js获取当前试题的内容高度 获取当前试题存在的图片
import { unitConversion } from "@/core/util/util";
export const getQuestionList = async (index, width, setting, settingObj) => {
  const questionContentList = index.questionCntMsg;
  const processedItems = [];
  let indexkey = 0;
  for (const qitem of questionContentList) {
    indexkey++;
    let data = await getQuestionCtnCopy(qitem, width, setting, index, indexkey);
    processedItems.push(data);
  }
  let allHeight = 0;
  let heightAll = 0;
  processedItems.map((item, index) => {
    allHeight = item.questionDefaultHeight + allHeight;
    if (index > 0) {
      heightAll += item.questionDefaultHeight;
    }
  });
  // 添加判断  如果小于60 填空题默认等于60
  if (
    allHeight < 60 &&
    (index.hwQuestionType == 2 || index.hwQuestionType == 4)
  ) {
    processedItems[0].questionDefaultHeight = 60;
  }

  let data = {
    questionWidth: width,
    questionScore: index.questionScore || 0,
    questionId: index.questionId,
    question: index.question,
    id: index.id,
    hwQuestionType: index.hwQuestionType,
    materialId: index.materialId,
    parentMaterialId: index.parentMaterialId,
    questionNum: index.questionNum,
    questionFNum: index.questionFNum,
    hwQuestionId: index.hwQuestionId,
    questionKey: index.questionKey,
    sheetUuid: index.sheetUuid,
    questionPriority: index.questionPriority,
    lastMaterialId: index.lastMaterialId,
    questionLocation: index.questionLocation,
    questionContentList: processedItems,
    questionCntMsg: index.questionCntMsg,
    hiddenType: index.hiddenType,
    realQuestionId: index.realQuestionId,
    isMerge: index.isMerge,
    questionContent: index.oldItemQuestion.join(""),
    oldItemQuestion: index.oldItemQuestion,
    questionAnswer: index.questionAnswer || "",
    optionCount: index.optionCount,
  };
  // console.log(data);

  if (index.hwQuestionType == 5) {
    data.contentAllHeight = index.contentAllHeight || 300;
    data.contentInfo = index.contentInfo || "";
    data.contentInfoAdd = index.contentInfoAdd || "";
  }
  // 如果是解答题的情况 就需要添加判断 给当前试题添加好 需要作答区域的方式
  return data;
};
// 获取普通dom节点
export const getDomObj = (item, width) => {
  let groupExtInfo = JSON.parse(item.groupExtInfo);
  let dom = groupExtInfo.cnt;
  let div = document.createElement("div");
  let offsetHeight = 0;
  // 将文本节点添加到div元素中
  div.innerHTML = dom;
  div.style.width = width + "px";
  const fDiv = document.getElementById("initQuestion");
  fDiv.appendChild(div);
  offsetHeight = div.offsetHeight;
  let data = {
    height: offsetHeight,
    width: width,
    htmlDom: dom,
  };
  // console.log(data);
  fDiv.removeChild(div);
  // 如果是解答题的情况 就需要添加判断 给当前试题添加好 需要作答区域的方式
  return data;
};
// 复制考试问题
const getQuestionCtnCopy = async (
  question,
  width,
  setting,
  qindex,
  indexkey
  // questionContentList
  // settingObj
) => {
  let question_html = question;
  let { fontSize, isScore, lineHeight } = setting;
  let offsetHeight = "";
  // 创建一个新的style元素
  var styleElement = document.createElement("style");
  // 写入CSS样式
  styleElement.innerHTML = cssStyle;
  // 将style元素添加到head中
  const fDiv = document.getElementById("initQuestion");
  fDiv.appendChild(styleElement);
  // 创建一个div元素
  let div = document.createElement("div");
  // 无分数需要 宽度是60 所以减去72 有分数是80 所以减去92
  if (qindex.hwQuestionType == 2 || qindex.hwQuestionType == 4) {
    width = width - (isScore == 0 ? 70 : 92);
  }
  div.style.width = width + "px";
  div.style.fontSize = fontSize + "px";
  div.style.lineHeight = lineHeight || 1.5;

  // 设置div的id属性
  div.setAttribute("id", qindex.questionId + "-" + indexkey);
  div.setAttribute("class", "find_end question-add-file question-cnt-box");
  // 将文本节点添加到div元素中
  div.innerHTML = question_html;
  // 将div元素添加到.question-cnt中

  fDiv.appendChild(div);
  const images = div.getElementsByTagName("img");
  let questionArr = [];
  // 获取当前试题里的图片
  for (let index = 0; index < images.length; index++) {
    questionArr.push(
      getRelativePosition(images[index], qindex.questionId + "-" + indexkey)
    );
  }

  await delay();
  // 创建一个Promise，用于加载所有图片
  await loadAllImages(questionArr);
  // 使用这个函数
  offsetHeight = div.offsetHeight;
  fDiv.removeChild(div);
  fDiv.removeChild(styleElement);
  // 替换图片为div包裹

  // 添加判断  如果是填空题  并且是手写区域的时候 本来是51 为了有间距  给个80
  // if (
  //   (qindex.hwQuestionType == 2 || qindex.hwQuestionType == 4) &&
  //   offsetHeight < 60 &&
  //   questionContentList.length == 1
  // ) {
  //   offsetHeight = 60;
  // }
  let data = {
    questionDefaultHeight: offsetHeight,
    questionDefaultWidth: width,
    imgArr: questionArr,
    question: question,
    id: qindex.questionId + "-" + indexkey,
  };

  // 如果是解答题的情况 就需要添加判断 给当前试题添加好 需要作答区域的方式
  return data;
};

/**
 *
 * @param {*} question 当前试题
 * @param {*} width 当前页面需要显示的宽度
 * @param {*} setting 页面渲染的参数
 * @returns
 */

// 创建一个Promise，用于加载所有图片
// eslint-disable-next-line no-unused-vars
const loadAllImages = async (questionArr) => {
  const promises = questionArr.map((url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = url.src;
    });
  });

  // 等待所有图片加载完成
  try {
    await Promise.all(promises);
    // 图片加载完成，现在可以安全地获取div的offsetHeight
  } catch (error) {
    // console.error('图片加载失败', error);
  }
};
function delay() {
  return new Promise((resolve) => setTimeout(resolve));
}

// 获取子节点相对位置
function getRelativePosition(img, id) {
  // 获取图片相对于指定元素的位置
  var position = img.getBoundingClientRect();
  // 获取id为"elementId"的指定元素
  var element = document.getElementById(id);
  // 计算图片相对于指定元素的偏移量
  var offsetX = position.left - element.getBoundingClientRect().left;
  var offsetY = position.top - element.getBoundingClientRect().top;
  // console.log(element.style.width);
  return {
    left: offsetX,
    top: offsetY,
    src: img.src,
  };
}

// 根据所选参数设置试题内容
/**
 *
 * @param {*} question 当前试题
 * @param {*} setting 需要传进来的配置参数
 * @param {*} setting.isShowQuestionNumber 是否显示题号
 * @param {*} setting.isAddprefix 是否选择题添加前缀
 */
export const setQuestionText = (index, setting, qHtml) => {
  let { isScore, isShowQuestionNumber, isAddprefix } = setting;
  let { questionNum, questionScore } = index;
  if (!questionScore || questionScore == 0) {
    questionScore = "-";
  }

  let question = index.question;
  if (qHtml) {
    question = qHtml;
  }
  let numTag = questionNum + ".</span>";
  question = question.replaceAll("（　　）", "（）");
  // 中文括号替换为英文括号
  question = question.replaceAll("（", "(");
  question = question.replaceAll("）", ")");
  // question = question.replaceAll("margin-top:", "marginTop:");
  if (index.parentMaterialId) {
    numTag = "<span>（" + questionNum + "）</span>";
  }
  if (isShowQuestionNumber == 0) {
    numTag = ".</span>";
  }
  if (
    (index.hwQuestionType == 1 || index.hwQuestionType == 3) &&
    isAddprefix == 1
  ) {
    if (index.parentMaterialId) {
      numTag =
        "<span style='display:inline-block;white-space: nowrap;width:60px;text-align: justify;font-size:14px!important;text-indent: 0;margin-left: -6px;'>【\x26#12288;\x26#12288;\x26#12288;】</span> <span >" +
        numTag;
    } else {
      numTag =
        "<span style='display:inline-block;white-space: nowrap;width:68px;text-align: justify;font-size:14px!important;text-indent: 0;margin-left: -6px;'>【\x26#12288;\x26#12288;\x26#12288;】</span> <span >" +
        numTag;
    }
  }
  if (question.startsWith("<p") || question.startsWith("<div")) {
    let numIndex = question.indexOf(">");
    question =
      question.substr(0, numIndex + 1) +
      numTag +
      `${isScore == 1 ? "(" + questionScore + "分)" : ""}` +
      question.substr(numIndex + 1);
  } else {
    question =
      numTag + `${isScore == 1 ? "(" + questionScore + "分)" : ""}` + question;
  }

  return question;
};
let settingHtml = {};
export const getViewQuestionList = async (index, setting) => {
  settingHtml = setting;
  const questionContentList = index.questionContentList;
  const processedItems = [];
  let indexNum = 0;
  for (const item of questionContentList) {
    let qustion = await getViewQuestion(item, index, indexNum);
    indexNum++;
    processedItems.push(qustion);
  }
  return processedItems;
};
// 基于img创建新的div
const getNewDiv = (img, id, indexQuestion) => {
  let hwQuestionType = indexQuestion.hwQuestionType;
  // 拷贝一份dom
  let imgStr = img.outerHTML;
  const imgDom = document.createElement("div");
  imgDom.innerHTML = imgStr;
  let arr = ["svg+xm", ".svg"];

  //图片盒子
  let fspan = document.createElement("span");
  arr.map((item) => {
    if (imgDom.firstChild.src.indexOf(item) != -1) {
      fspan.setAttribute("class", "is-svg");
    }
  });
  fspan.id = "span-" + id;
  let span = document.createElement("span");
  span.setAttribute("class", "edit-img-box");
  let float = imgDom.firstChild.style.float;
  imgDom.firstChild.style.float = "unset";
  imgDom.firstChild.id = id;
  imgDom.firstChild.setAttribute("class", "is-edit");
  // fspan.style.width = imgDom.firstChild.style.width;
  let imgWidth = imgDom.firstChild.style.width;
  let imgHeight = imgDom.firstChild.style.height;
  let imgLwidth = imgDom.firstChild.width;
  let imgLHeight = imgDom.firstChild.imgLHeight;
  // 图片
  if (imgWidth) {
    // 如果是百分比图片
    if (imgWidth.indexOf("%") != -1) {
      let indexWidth = imgWidth.split("%")[0];
      let paperWidth = new unitConversion().mmConversionPx(
        settingHtml.paperInfo.paperWidth
      );
      let width = (paperWidth * indexWidth) / 100;
      imgDom.firstChild.style.width = width + "px";
    }
  }
  if (imgHeight) {
    // 如果是百分比图片
    if (imgHeight.indexOf("%") != -1) {
      let indexHeight = imgHeight.split("%")[0];
      let paperHeight = new unitConversion().mmConversionPx(
        settingHtml.paperInfo.paperHeight
      );
      let height = (paperHeight * indexHeight) / 100;
      imgDom.firstChild.style.height = height + "px";
    }
  }
  if (imgLwidth && !imgWidth) {
    imgDom.firstChild.style.width = imgLwidth + "px";
  }
  if (imgLHeight && !imgHeight) {
    imgDom.firstChild.style.height = imgLHeight + "px";
  }

  imgDom.firstChild.style.maxWidth = "100%";
  // 添加判断  图片超大了之后  禁止
  if (imgDom.firstChild.style.height) {
    let height = imgDom.firstChild.style.height;
    let num = height.split("px")[0];
    num = num.split("PX")[0];
    num = Number(num);
    if (num > 650) {
      imgDom.firstChild.style.height = "650px";
    }
  }

  if (imgDom.firstChild.style.width) {
    let width = imgDom.firstChild.style.width;
    let num = width.split("px")[0];
    num = num.split("PX")[0];
    num = Number(num);
    if (num > 737) {
      imgDom.firstChild.style.width = "737px";
    }
    if (hwQuestionType == 2) {
      if (num > 650) {
        imgDom.firstChild.style.width = "737px";
      }
    }
  }

  // fspan.style.width = imgDom.firstChild.style.width;
  span.appendChild(imgDom.firstChild);
  //添加右按钮
  const cDiv = document.createElement("span");
  cDiv.setAttribute("class", "edit-operation-img edit-operation-right");
  cDiv.setAttribute("img-id", id);
  cDiv.setAttribute("hw-type", hwQuestionType);
  cDiv.setAttribute("zoom-type", "right");
  span.appendChild(cDiv);
  //添加下按钮
  const cDivBtm = document.createElement("span");
  cDivBtm.setAttribute("class", "edit-operation-img edit-operation-bottom");
  cDivBtm.setAttribute("img-id", id);
  cDivBtm.setAttribute("hw-type", hwQuestionType);
  cDivBtm.setAttribute("zoom-type", "bottom");
  span.appendChild(cDivBtm);
  //缩放
  const zoomBtm = document.createElement("span");
  zoomBtm.setAttribute("class", "edit-operation-img edit-operation-zoom");
  zoomBtm.setAttribute("img-id", id);
  zoomBtm.setAttribute("hw-type", hwQuestionType);
  zoomBtm.setAttribute("zoom-type", "zoom");
  span.appendChild(zoomBtm);
  // 添加几个操作按钮   右浮动  左对齐 右对齐  居中对齐 左浮动
  const ckecBtn = document.createElement("span");
  ckecBtn.setAttribute("class", "edit-operation-box");
  const typeItem = ["left", "center", "right", "left-text", "right-text"];
  typeItem.map((item) => {
    const btn = getNewBtn(item, id, hwQuestionType);
    ckecBtn.appendChild(btn);
  });

  span.appendChild(ckecBtn);
  // ckecBtn.setAttribute("img-id", id);
  // span.style.display = "inline-block";
  fspan.style.float = float;
  fspan.style.display = "inline-block";
  fspan.appendChild(span);
  return fspan;
  // 添加按钮
  function getNewBtn(type, id) {
    const leftBtn = document.createElement("span");
    leftBtn.setAttribute("class", `click-img-btn click-btn-${type}`);
    leftBtn.setAttribute("img-id", id);
    leftBtn.setAttribute("check-type", type);
    return leftBtn;
  }
};
// 将div下的img替换为指定div下的节点
/**
 *
 * @param {*} div 替换的dom
 * @param {*} images 图片列表
 * @param {*} startStr id字符串
 * @param {*} indexQuestion 当前试题  用来判断是什么题型
 * @returns 返回替换好的dom
 */
const replaceImgToDiv = (div, images, startStr, indexQuestion) => {
  // 获取当前试题里的图片
  for (let index = 0; index < images.length; index++) {
    // svg+xml和.svg结尾
    // if (index !== null) return;

    if (
      !images[index].getAttribute("class") ||
      (images[index].getAttribute("class") &&
        images[index].getAttribute("class").indexOf("is-edit") == -1)
    ) {
      replaceAllImages(div, images[index], index);
    }
  }

  // 替换所有 img 子孙节点
  function replaceAllImages(node, image, index) {
    // 遍历所有子节点
    const children = node.childNodes;
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      // 如果是 img 元素，替换它
      if (child.src === image.src) {
        let findId = child.getAttribute("find-img");
        let image_find = image.getAttribute("find-img");
        if (findId == image_find) {
          const div = getNewDiv(image, startStr + "_" + index, indexQuestion);
          node.replaceChild(div, child);
        }
      } else if (child.childNodes && child.childNodes.length > 0) {
        // 如果子节点还有子节点，递归调用
        replaceAllImages(child, image, index);
      }
    }
  }
  // console.log(div);
  return div;
};

// 将div下的img替换为指定div下的节点
const replaceQutToDiv = (node) => {
  var number = 0;
  replaceDiv(node);

  function getNewSpan() {
    const span = document.createElement("span");
    span.setAttribute("class", "student-answer-box");
    let str =
      "\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;\x26#12288;";
    span.innerHTML = str;
    span.style.letterSpacing = 0;
    span.style.whiteSpace = "nowrap";
    // white-space: nowrap;
    span.style.lineHeight = "2.5";
    span.style.borderBottom = "1px solid black";
    return span;
  }

  // 替换所有 quizPutTag 子孙节点
  function replaceDiv(node) {
    // 遍历所有子节点
    const children = node.childNodes;
    if (children) {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        let str = "";
        if (child && child.classList) {
          str = child.classList[0] || "";
        }
        if (child.src) {
          let c_str = "img-" + number;
          number++;
          child.setAttribute("find-img", c_str);
        }

        // 如果是 quizPutTag 元素，替换它
        if (str.indexOf("quizPutTag") != -1) {
          const span = getNewSpan();
          node.replaceChild(span, child);
        } else if (child.childNodes) {
          // 如果子节点还有子节点，递归调用
          replaceDiv(child);
        }
      }
    }
  }
  // console.log(node);
  return node;
};
// 替换出页面需要显示的节点
export const getViewQuestion = async (index, indexQuestion, indexNum) => {
  let question = index;
  // 创建一个div元素
  let div = document.createElement("div");
  // let oldDiv = document.createElement("div");
  // let copyDiv = document.createElement("div");
  div.innerHTML = question;
  // copyDiv.innerHTML = question;

  await delay();
  div = replaceQutToDiv(div);

  const images = div.getElementsByTagName("img");
  let questionArr = [];
  // 获取当前试题里的图片
  for (let indexkey = 0; indexkey < images.length; indexkey++) {
    questionArr.push({ src: images[indexkey].src });
  }
  // 创建一个Promise，用于加载所有图片
  let startStr = "find_" + indexQuestion.questionId + "_" + indexNum;
  await loadAllImages(questionArr);
  div = replaceImgToDiv(div, images, startStr, indexQuestion);
  await delay();

  let htmlText = div.innerHTML;
  htmlText = htmlText.replaceAll("margin-top", "marginTop");
  return htmlText;
};
// 重新排序试题 对试题内容进行二次分配
export function getQuestionSort(questionTypeList, setting) {
  let { questionModel } = setting;
  // console.log(questionModel);
  let indexNumsKey = 0;
  let materialId = 0;
  let hwQuestionType = 0;
  questionTypeList = questionTypeList.map((item) => {
    if (item.questionList) {
      item.questionList = item.questionList.map((index) => {
        if (index.materialId) {
          indexNumsKey = index.questionNum;
          hwQuestionType = index.hwQuestionType;
          materialId = index.materialId;
          // item.hwQuestionType = hwQuestionType;
        }
        if (materialId == index.parentMaterialId && index.parentMaterialId) {
          index.questionFNum = indexNumsKey;
          index.fType = hwQuestionType;
        }
        // 如果是理科复合体  需要将框框组合在一起
        if (questionModel == 2 && index.parentMaterialId) {
          let arr = item.questionList.filter(
            (i) => i.parentMaterialId == index.parentMaterialId
          );
          let array = arr.filter((i) => i.hwQuestionType == 5);
          let newList;
          if (array.length > 0) {
            newList = generateArray(array.length);
          } else {
            newList = [];
          }

          if (
            arr.length > 0 &&
            index.questionId == arr[arr.length - 1].questionId
          ) {
            index.lastMaterialId = materialId;

            let questionArr = index.questionLocation || [];
            if (questionArr.length == 0) {
              let ids = array.map((item) => {
                return {
                  questionId: item.questionId,
                  questionKey: item.questionKey,
                  questionScore: item.questionScore,
                  questionNum: item.questionNum,
                  questionFNum: index.questionFNum,
                };
              });

              let number = 0;
              questionArr = newList.map((i, key) => {
                let data = {
                  nums: i,
                  contentAllHeight: 200,
                  contentHeight: 200,
                  scoreId:
                    new Date().getTime() +
                    "-" +
                    index.parentMaterialId +
                    "-" +
                    key,
                  questionList: [],
                };
                for (let j = 0; j < i; j++) {
                  data.questionList.push(ids[number]);
                  number++;
                }
                return data;
              });
            } else {
              let iIndex = 0;
              questionArr = questionArr.map((i, key) => {
                i.questionList = i.questionList.map((iItem) => {
                  iItem.questionScore = array[iIndex].questionScore;
                  iIndex++;
                  return iItem;
                });
                i.scoreId =
                  new Date().getTime() +
                  "-" +
                  index.parentMaterialId +
                  "-" +
                  key;
                return i;
              });
            }
            index.questionLocation = questionArr;
          }
        }
        if (questionModel == 1) {
          index.lastMaterialId = 0;
        }

        return index;
      });
    }
    return item;
  });
  // console.log(JSON.parse(JSON.stringify(questionTypeList)));
  return questionTypeList;
}
// 用于生成一个由数字2和3组成的数组，其长度为`n`。若`n`为1，则返回仅含1的数组。否则，优先填充数字3，不足时填充2，最后反转数组。
function generateArray(n) {
  const arr = [];
  let count = n;

  if (count === 1) {
    arr.push(1);
  } else {
    while (count > 0) {
      if (count % 3 === 0) {
        arr.push(3);
        count -= 3;
      } else {
        arr.push(2);
        count -= 2;
      }
    }
  }
  return arr.reverse();
}
