import request from "@/core/services/axios";

// 新增作业模板
export function homeworksheet(query) {
  return request({
    url: "/homework/homeworksheet",
    method: "post",
    data: query,
  });
}

// 通过id查询详细信息
export function getInfo(query) {
  return request({
    url: "/homework/homeworksheet/getInfo",
    method: "get",
    params: query,
  });
}

// 保存HTML、CSS和扩展数据
export function saveContent(query) {
  return request({
    url: "/homework/homeworksheet/saveContent",
    method: "post",
    data: query,
  });
}

// 获取HTML、CSS和扩展数据
export function getContent(query) {
  return request({
    url: "/homework/homeworksheet/getContent",
    method: "get",
    params: query,
  });
}

// 通过UUID将作业卡HTML文本转换为PDF
export function getImage(query) {
  return request({
    url: "/homework/homeworksheet/getImage",
    method: "get",
    params: query,
  });
}
// 通过UUID将作业卡HTML文本转换为PDF
export function getPdf(query) {
  return request({
    url: "/homework/homeworksheet/getPdf",
    method: "get",
    params: query,
  });
}
// 根据临时作业id获取预览试题列表
export function getquestiontemplist(query) {
  return request({
    url: "/homework/homework/getquestiontemplist",
    method: "get",
    params: query,
  });
}
// 编辑修改 预览
export function updatequestiontemplist(query) {
  return request({
    url: "/homework/homework/updatequestiontemplist",
    method: "post",
    data: query,
  });
}
//删除作业卡试题
export function deleteQuestion(query) {
  return request({
    url: "/homework/homeworksheet/deleteQuestion",
    method: "post",
    data: query,
  });
}
//修改作业卡试题
export function updateQuestionList(query) {
  return request({
    url: "/homework/homeworksheet/updateQuestionList",
    method: "post",
    data: query,
  });
}
//修改作业卡试题 新
export function updateQuestion(query) {
  return request({
    url: "/homework/homeworksheet/updateQuestion",
    method: "post",
    data: query,
  });
}
//修改试题排序方式
export function updateQuestionTypeRanking(query) {
  return request({
    url: "/homework/homeworksheet/updateQuestionTypeRanking",
    method: "post",
    data: query,
  });
}
// 删除作业
export function deletequestiontemp(query) {
  return request({
    url: "/homework/homework/deletequestiontemp",
    method: "delete",
    params: query,
  });
}
// 作业上传-保存为试卷
export function savetopaper(query) {
  return request({
    url: "/homework/homework/savetopaper",
    method: "get",
    params: query,
  });
}
// 新增作业卡分组
export function saveGroup(query) {
  return request({
    url: "/homework/homeworksheet/saveGroup",
    method: "post",
    data: query,
  });
}
// 修改作业卡分组
export function updateGroup(query) {
  return request({
    url: "/homework/homeworksheet/updateGroup",
    method: "post",
    data: query,
  });
}
// 获取作业卡标注信息
export function testMark(query) {
  return request({
    url: "/recognition/homework/test/mark",
    method: "get",
    params: query,
  });
}
// 获取作业卡解析
export function getAnswer(query) {
  return request({
    url: "/homework/homeworksheet/getAnswer",
    method: "get",
    params: query,
  });
}
// 获取答案列表
export function getAnswerList(query) {
  return request({
    url: "/homework/homeworksheet/getAnswerList",
    method: "get",
    params: query,
  });
}
// 设置答案列表
export function upateAnswerList(query) {
  return request({
    url: "/homework/homeworksheet/updateAnswerList",
    method: "post",
    data: query,
  });
}
