function base64ToBlob(base64Data, contentType) {
  // 去除头部信息（data:image/jpeg;base64,）, 只保留base64编码的字符串
  base64Data = base64Data.split(",")[1];
  // 解码Base64字符串
  let binaryData = window.atob(base64Data);
  // 转换为Uint8Array
  let arrayBuffer = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    arrayBuffer[i] = binaryData.charCodeAt(i);
  }

  // 创建Blob对象
  return new Blob([arrayBuffer], { type: contentType });
}
/**
 *
 * @param {*} base64Data 需要转换的文件
 * @param {*} fileName 类型
 * @param {*} contentType 名称
 * @returns file对象
 */
// base64转file对象
export function base64ToFile(
  base64Data,
  fileName = "image/jpeg",
  contentType = "upload.jpg"
) {
  // 使用上面定义的函数创建Blob
  let blob = base64ToBlob(base64Data, contentType);

  // 创建File对象
  return new File([blob], fileName, { type: contentType });
}
