<template>
  <div class="editQuestion">
    <el-dialog
      title="编辑试题 "
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="980px"
      class="edit-question-model"
    >
      <div v-loading="viewLoading">
        <div ref="questionBox" class="question-edit-box edit-scroll-style">
          <div class="question-check">
            题型：
            <el-select
              v-model="subData.hwQuestionType"
              placeholder="请选择"
              style="margin-right: 8px"
              @change="checkFType()"
            >
              <el-option
                v-for="item in questionTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>

              <el-option
                v-if="hwSub.indexOf(settingObj.subjectId) != -1"
                label="复合题"
                :value="12"
              >
              </el-option>
              <el-option v-else label="复合题" :value="11"> </el-option>
            </el-select>
            难度：
            <el-select
              v-model="subData.difficulty"
              placeholder="请选择"
              @change="checkdType()"
            >
              <el-option
                v-for="item in difficultyList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-tooltip
              class="item"
              effect="dark"
              content="合并题干在图片右浮动情况下体验更佳！"
              placement="top"
            >
              <el-checkbox
                v-model="isMerge"
                style="margin-left: 18px"
                :true-label="1"
                :false-label="0"
                >合并题干</el-checkbox
              >
            </el-tooltip>
          </div>
          <div class="know-box">
            <div class="label">知识点：</div>
            <div v-if="subData.knowledgeNames" class="know-item">
              <div
                v-for="(item, index) in subData.knowledgeNames"
                :key="index"
                class="know-item-text"
              >
                {{ item }}
                <i class="el-icon-close" @click="delKnow(index)"></i>
              </div>
            </div>
            <el-button
              size="mini"
              type="primary"
              plain
              icon="el-icon-plus"
              style="padding: 8px 8px"
              @click="addBtn()"
            >
              添加
            </el-button>
          </div>
          <div class="sub-title"><span>*</span>题干</div>
          <CKEditor
            :id="subQuestion.questionId"
            :ref="subQuestion.questionId"
            :key="ckKey"
            v-model="subQuestion.originalQuestion"
            :config="getConfig(subQuestion.questionId)"
            :editor-url="editorUrl"
            @ready="onEditorReady"
          />
          <template
            v-if="
              subQuestion.hwQuestionType == 1 || subQuestion.hwQuestionType == 3
            "
          >
            <div class="option-item-box">
              <div class="sub-title"><span>*</span>答案</div>
              <div
                v-for="(item, index) in optionArr"
                :key="index"
                class="option-item"
                :class="{
                  'option-item-on': subQuestion.answer.indexOf(item) != -1,
                }"
                @click="subAnswer(subQuestion, item)"
              >
                {{ item }}
              </div>
              <div class="btn-item" @click="checkCntItem('questionData', -1)">
                <i class="el-icon-minus"></i>
              </div>
              <div class="btn-item">
                <i
                  class="el-icon-plus"
                  @click="checkCntItem('questionData', 1)"
                ></i>
              </div>
            </div>
          </template>
          <template
            v-if="
              subQuestion.hwQuestionType == 2 ||
              subQuestion.hwQuestionType == 4 ||
              subQuestion.hwQuestionType == 5
            "
          >
            <div class="sub-title" style="margin-top: 8px">
              <span></span>答案
            </div>
            <div class="answer-box">
              <CKEditor
                :key="ckKey + '1'"
                :ref="subQuestion.questionId + 'answer'"
                v-model="subQuestion.answer"
                :config="getConfig()"
                :editor-url="editorUrl"
                @ready="onEditorReady"
              />
            </div>
          </template>
          <template
            v-if="
              questionData.hwQuestionType != 11 &&
              questionData.hwQuestionType != 12
            "
          >
            <div class="sub-title" style="margin-top: 8px">解析</div>
            <CKEditor
              :key="ckKey + '0001'"
              :ref="subQuestion.webId + 'answer'"
              v-model="subQuestion.analysis"
              :config="getConfig(subQuestion.webId + 'answer')"
              :editor-url="editorUrl"
              @ready="onEditorReady"
            />
          </template>
          <template
            v-if="
              questionData.hwQuestionType == 11 ||
              questionData.hwQuestionType == 12
            "
          >
            <div class="option-item-box" style="margin: 18px 0 10px">
              <div
                v-for="(item, index) in questionData.children"
                :key="index"
                class="option-item"
                :class="{
                  'option-item-on': item.webId == indexShowCnt.webId,
                }"
                draggable="true"
                @click="checkItem(item, index)"
                @dragstart.self="onDragStart($event, item)"
                @dragover.prevent
                @drop.self="onDrop($event, item, index)"
              >
                ({{ item.questionFNum }} - {{ item.questionNum }})
                <i
                  v-if="questionData.children.length > 1"
                  class="el-icon-error"
                  @click.stop="delChild(item)"
                ></i>
              </div>
              <el-button
                type="primary"
                plain
                icon="el-icon-plus"
                size="medium"
                @click="addQuestion()"
              >
                添加小题
              </el-button>
            </div>
            <div class="question-check">
              题型：
              <el-select
                v-model="subIndexObj.hwQuestionType"
                placeholder="请选择"
                style="margin-right: 8px"
                @change="checkFSubType()"
              >
                <el-option
                  v-for="item in questionTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="sub-title"><span>*</span>题干</div>
            <CKEditor
              :key="ckKeyTwo + '11126'"
              :ref="indexShowCnt.webId"
              v-model="indexShowCnt.originalQuestion"
              v-loading="checkLoading"
              :config="getConfig(indexShowCnt.webId)"
              :editor-url="editorUrl"
              @ready="onEditorReady"
            />
            <template
              v-if="
                indexShowCnt.hwQuestionType == 1 ||
                indexShowCnt.hwQuestionType == 3
              "
            >
              <div class="option-item-box">
                <div class="sub-title"><span>*</span>答案</div>
                <div
                  v-for="(item, index) in optionChildArr"
                  :key="index"
                  class="option-item"
                  :class="{
                    'option-item-on': indexShowCnt.answer.indexOf(item) != -1,
                  }"
                  @click="subAnswer(indexShowCnt, item)"
                >
                  {{ item }}
                </div>
                <div class="btn-item" @click="checkCntItem('indexShowCnt', -1)">
                  <i class="el-icon-minus"></i>
                </div>
                <div class="btn-item">
                  <i
                    class="el-icon-plus"
                    @click="checkCntItem('indexShowCnt', 1)"
                  ></i>
                </div>
              </div>
            </template>
            <template
              v-if="
                indexShowCnt.hwQuestionType == 2 ||
                indexShowCnt.hwQuestionType == 4 ||
                indexShowCnt.hwQuestionType == 5
              "
            >
              <div class="sub-title" style="margin-top: 8px">
                <span></span>答案
              </div>
              <div class="answer-box">
                <CKEditor
                  :key="ckKeyOne + '3'"
                  :ref="indexShowCnt.questionId + 'answer'"
                  v-model="indexShowCnt.answer"
                  :config="getConfig()"
                  :editor-url="editorUrl"
                  @ready="onEditorReady"
                />
              </div>
            </template>
          </template>
          <template
            v-if="
              questionData.hwQuestionType == 11 ||
              questionData.hwQuestionType == 12
            "
          >
            <div class="sub-title" style="margin-top: 8px">解析</div>
            <CKEditor
              :key="ckKeyOne + '000133'"
              :ref="indexShowCnt.webId + 'answer'"
              v-model="indexShowCnt.analysis"
              :config="getConfig(indexShowCnt.webId + 'answer')"
              :editor-url="editorUrl"
              @ready="onEditorReady"
            />
          </template>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" :loading="btnLoading" @click="lintFrom()">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <knowledge ref="knowledge"></knowledge>
  </div>
</template>
<script>
import CKEditor from "ckeditor4-vue";
import { difficultyList } from "@/core/util/constdata";
import { sheetWorkImg } from "@/core/api/exam/sheet";
import { base64ToFile } from "@/core/util/utilFile";
import { getInfo, updateQuestion } from "@/core/api/homework/sheet";
import { getStore } from "@/core/util/store";
import { mapGetters } from "vuex";
import knowledge from "./knowledge";

const optionStr = "ABCDEFGHIJKLMNOPQRSTVWXYZ";
const questionTypeList = [
  { value: 1, label: "选择题" },
  { value: 2, label: "填空题" },
  { value: 3, label: "多选题" },
  { value: 4, label: "判断题" },
  { value: 5, label: "解答题" },
];
export default {
  name: "EditQuestion",
  components: {
    CKEditor: CKEditor.component,
    knowledge,
  },
  data() {
    return {
      optionArr: [],
      optionChildArr: [],
      hwSub: "2,8,9",
      settingObj: {},
      questionData: {},
      subData: {},
      questionTypeList: questionTypeList,
      difficultyList: difficultyList,
      dialogVisible: false,
      btnLoading: false,
      checkLoading: false,
      modelLoading: false,
      isMerge: 0,
      ckKey: 1,
      ckKeyOne: 1,
      ckKeyTwo: 1,
      optionNumber: 4,
      editorData: "",
      editorUrl: "./ckeditor/ckeditor.js",
      editorConfig: {
        title: true,
        name: "editor",
        language: "zh-cn",
        allowedContent: true,
        wirisintegration: true,
        mathJaxClass: "equation",
        // ckeditor_wiris
        extraPlugins:
          "image,pastefromword,longTextline,shortTextline,CustomImageUpload,drawingGrid,drawingImg,clearCssStyles",
        removePlugins: "exportpdf,showborders,Format",
        font_names: "宋体;" + "楷体",
        toolbar: [
          {
            name: "basicstyles",
            items: [
              "basicstyles",
              "Bold",
              "Underline",
              "Italic",
              "Strike",
              "TextColor",
              "BGColor",
              "quicktable",
              "shortTextline",
              "lineheight",
              "ShortMatts",
              "Grid",
              "RemoveFormat",
              "CustomImageUpload",
              "clearCssStyles",
            ],
          },
          //              "ckeditor_wiris_formulaEditor",
          // "ckeditor_wiris_formulaEditorChemistry",
          {
            name: "insert",
            items: ["SpecialChar"],
          },
          {
            name: "align",
            items: ["JustifyLeft", "JustifyCenter", "JustifyRight"],
          },
          { name: "styles", items: ["Font", "FontSize"] },
        ],
      },
      indexShowCnt: {},
      subQuestion: {},
      subIndexObj: {},
      viewLoading: false,
    };
  },
  computed: {
    ...mapGetters(["GET_HW_QUESTION_LIST"]),
  },
  created() {},
  updated() {},
  methods: {
    onDragStart(event, item) {
      this.dragData = item;
      event.dataTransfer.setData("text/plain", "");
      document.body.classList.add("dragging");
    },
    onDrop(event, item, index) {
      if (!this.dragData) return;
      const dragIndex = this.questionData.children.indexOf(this.dragData);
      if (dragIndex === -1 || dragIndex === index) return;
      let data = JSON.parse(JSON.stringify(this.dragData));
      this.questionData.children.splice(dragIndex, 1);
      this.questionData.children.splice(index, 0, data);
      this.dragData = null;
      document.body.classList.remove("dragging");
      this.setQuestionItem();
    },
    setQuestionItem() {
      this.questionData.children = this.questionData.children.map(
        (item, index) => {
          item.questionNum = index + 1;
          return item;
        }
      );
      this.questionData.children = JSON.parse(
        JSON.stringify(this.questionData.children)
      );
    },
    delChild(item) {
      let question = false;
      if (this.indexShowCnt.webId == item.webId) {
        if (this.indexShowCnt.originalQuestion) {
          question = true;
        }
      } else {
        if (item.originalQuestion) {
          question = true;
        }
      }
      if (question) {
        this.$confirm(
          "当前试题存在内容，删除后不可恢复，是否继续?",
          "温馨提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.delItem(item);
          })
          .catch(() => {});
        return;
      }
      this.delItem(item);
    },
    delItem(item) {
      this.questionData.children = this.questionData.children.filter(
        (index) => index.webId != item.webId
      );
      this.setQuestionItem();
      if (this.indexShowCnt.webId == item.webId) {
        let data =
          this.questionData.children[this.questionData.children.length - 1];
        this.indexQuestion = JSON.parse(JSON.stringify(data));
        this.subIndexObj.hwQuestionType = this.indexQuestion.hwQuestionType;
        this.ckKeyOne = new Date().getTime();
        this.ckKeyTwo = new Date().getTime();
      }
    },
    addQuestion() {
      let num = this.questionData.children.length;
      let data = JSON.parse(JSON.stringify(this.getNewQuestion(num)));
      this.questionData.children.push(data);

      this.setQuestionItem();
    },
    getNewQuestion(key) {
      let data = {
        id: "",
        originalQuestion: "",
        answer: "",
        hwQuestionType: 5,
        optionCount: 4,
        score: 0,
        questionFNum: this.questionData.questionNum,
        parentMaterialId: this.questionData.materialId,
        analysis: "",
        questionNum: key + 1,
        webId: new Date().getTime() + "-" + key,
      };
      data = JSON.parse(JSON.stringify(data));
      return data;
    },
    setQuestion(question, index) {
      let numTag = "<span>（" + index + "）</span>";
      if (question.startsWith("<p") || question.startsWith("<div")) {
        let numIndex = question.indexOf(">");
        question =
          question.substr(0, numIndex + 1) +
          numTag +
          question.substr(numIndex + 1);
      } else {
        question = numTag + question;
      }
      return question;
    },
    showEditQuestion() {
      if (
        !this.questionData.children ||
        this.questionData.children.length == 0
      ) {
        return;
      }
      this.$confirm("是否将小题合并到大题?", "温馨提示", {
        confirmButtonText: "合并",
        cancelButtonText: "不合并",
        type: "warning",
      })
        .then(() => {
          let arr = this.questionData.children.map(
            (item) => item.originalQuestion
          );
          arr = arr.filter((item) => item);
          arr = arr.map((item, index) => {
            item = this.setQuestion(item, index + 1);
            return item;
          });

          this.subQuestion.originalQuestion =
            this.subQuestion.originalQuestion + arr.join("");
          this.questionData.children = [];
        })
        .catch(() => {});
    },
    checkFType() {
      this.$confirm("切换后可能丢失已经埴入的信息，确定切换?", "温馨提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.checkType();
        })
        .catch(() => {
          this.subData.hwQuestionType = this.questionData.hwQuestionType;
        });
    },
    checkType() {
      if (
        this.questionData.hwQuestionType == 11 ||
        this.questionData.hwQuestionType == 12
      ) {
        this.showEditQuestion();
      }
      if (
        this.subData.hwQuestionType == 11 ||
        this.subData.hwQuestionType == 12
      ) {
        if (
          this.questionData.children &&
          this.questionData.children.length > 0
        ) {
          this.indexQuestion = JSON.parse(
            JSON.stringify(this.questionData.children[0])
          );
        } else {
          let data = JSON.parse(JSON.stringify(this.getNewQuestion(0)));
          this.indexQuestion = data;
          this.questionData.children = [];
          this.questionData.children.push(data);
          this.subIndexObj.hwQuestionType = 5;
          this.subIndexObj = JSON.parse(JSON.stringify(this.subIndexObj));
        }
        this.ckKeyOne = new Date().getTime();
        this.ckKeyTwo = new Date().getTime();
      } else {
        this.indexQuestion = {};
      }
      if (
        this.subData.hwQuestionType == 1 ||
        this.subData.hwQuestionType == 3
      ) {
        if (
          this.subQuestion.hwQuestionType != 1 ||
          this.subQuestion.hwQuestionType != 3
        ) {
          this.questionData.answer = [];
          this.subQuestion.answer = [];
        }
      } else {
        if (
          this.subQuestion.hwQuestionType == 1 ||
          this.subQuestion.hwQuestionType == 3
        ) {
          this.questionData.answer = this.questionData.answer.join("");
          this.subQuestion.answer = this.subQuestion.answer.join("");
        }
      }
      this.questionData.hwQuestionType = this.subData.hwQuestionType;
      this.subQuestion.hwQuestionType = this.subData.hwQuestionType;
      this.subQuestion = JSON.parse(JSON.stringify(this.subQuestion));
    },
    checkFSubType() {
      this.$confirm("切换后可能丢失已经埴入的信息，确定切换?", "温馨提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.checkSubType();
        })
        .catch(() => {
          this.subIndexObj.hwQuestionType = this.indexShowCnt.hwQuestionType;
        });
    },
    checkSubType() {
      if (
        this.subIndexObj.hwQuestionType == 1 ||
        this.subIndexObj.hwQuestionType == 3
      ) {
        if (
          this.indexShowCnt.hwQuestionType != 1 ||
          this.indexShowCnt.hwQuestionType != 3
        ) {
          this.indexShowCnt.answer = [];
          this.indexQuestion.answer = [];
        }
      } else {
        if (
          this.indexShowCnt.hwQuestionType == 1 ||
          this.indexShowCnt.hwQuestionType == 3
        ) {
          this.indexShowCnt.answer = this.indexShowCnt.answer.join("");
          this.indexQuestion.answer = this.indexQuestion.answer.join("");
        }
      }
      this.indexShowCnt.hwQuestionType = this.subIndexObj.hwQuestionType;
      this.indexQuestion.hwQuestionType = this.subIndexObj.hwQuestionType;
      this.ckKeyOne = new Date().getTime();
      this.ckKeyTwo = new Date().getTime();

      if (
        this.indexShowCnt.hwQuestionType == 1 ||
        this.indexShowCnt.hwQuestionType == 3
      ) {
        this.initcoption();
      }
    },
    checkdType() {
      this.questionData.difficulty = this.subData.difficulty;
      this.subQuestion.difficulty = this.subData.difficulty;
    },
    delKnow(i) {
      this.subData.knowledgeNames.splice(i, 1);
      this.subData.knowledgeIds.splice(i, 1);
    },
    setKnow(data) {
      Object.assign(this.subData, data);
      this.subData = JSON.parse(JSON.stringify(this.subData));
    },
    addBtn() {
      let arr = JSON.parse(JSON.stringify(this.subData.knowledgeIds));
      this.$refs.knowledge.init(arr);
    },
    lockScroll() {
      // 锁定滚动
      document.body.classList.add("no-scroll");
    },
    unlockScroll() {
      // 解锁滚动
      document.body.classList.remove("no-scroll");
    },
    checkItem(item) {
      if (this.checkLoading) return;
      this.checkLoading = true;
      this.questionData.originalQuestion = this.subQuestion.originalQuestion;
      this.questionData.analysis = this.subQuestion.analysis;
      this.questionData.children = this.questionData.children.map((index) => {
        if (this.indexShowCnt.webId == index.webId) {
          index = JSON.parse(JSON.stringify(this.indexShowCnt));
        }
        return index;
      });

      this.setQuestionItem();
      this.questionData.children.map((index) => {
        if (item.webId == index.webId) {
          this.indexQuestion = JSON.parse(JSON.stringify(index));
        }
      });
      // console.log(JSON.parse(JSON.stringify(this.indexQuestion)));
      setTimeout(() => {
        this.ckKeyOne = new Date().getTime();
        this.ckKeyTwo = new Date().getTime();
      }, 500);
    },
    subAnswer(question, item) {
      if (question.answer.indexOf(item) == -1) {
        question.answer.push(item);
      } else {
        question.answer = question.answer.filter((index) => index != item);
      }
    },
    checkCntItem(key, type) {
      if (type == 1 && this[key].optionCount == 8) {
        this.$message({
          message: "选项最多8个",
          type: "warning",
        });
        return;
      }

      if (type == -1 && this[key].optionCount == 2) {
        this.$message({
          message: "选项最少2个",
          type: "warning",
        });
        return;
      }

      this[key].optionCount = this[key].optionCount + type;
      this.initOption();
    },
    initOption() {
      this.initFoption();

      if (
        this.indexShowCnt.hwQuestionType == 1 ||
        this.indexShowCnt.hwQuestionType == 3
      ) {
        this.initcoption();
      }
    },
    initFoption() {
      let arr = optionStr.split("");
      arr.length = this.questionData.optionCount;
      this.optionArr = arr;
    },
    initcoption() {
      let arr = optionStr.split("");
      arr.length = this.indexShowCnt.optionCount;
      this.optionChildArr = arr;
    },
    getConfig(id) {
      let editorConfig = Object.assign({}, this.editorConfig);

      setTimeout(() => {
        // 重定义editor空格键
        if (id) {
          this.bindHeadEditor(id);
        }
      }, 500);
      return editorConfig;
    },
    // 重定义editor空格键
    bindHeadEditor(b) {
      if (!b) return;
      this.$nextTick(() => {
        if (!this.$refs[b]) return;
        var g = this.$refs[b].instance;
        // console.log(g);
        if (!g) return;
        g.on("key", function (a) {
          if (32 == a.data.keyCode) return g.insertHtml("\x26#12288;"), !1;
        });
      });
    },
    onEditorReady() {
      // console.log("111111");
      this.$nextTick(() => {
        if (!this.questionData || !this.questionData.questionId) return;
        this.subQuestion = JSON.parse(JSON.stringify(this.questionData));
        if (this.indexQuestion && this.indexQuestion.webId) {
          setTimeout(() => {
            this.indexShowCnt = JSON.parse(JSON.stringify(this.indexQuestion));
          }, 500);

          this.subIndexObj.hwQuestionType = this.indexQuestion.hwQuestionType;
        }

        setTimeout(() => {
          this.initOption();
          this.checkLoading = false;
        }, 500);
      });
    },
    // async handlePastedImage(htmlData) {
    //   // 解析HTML数据，提取图片URL
    //   let div = document.createElement("div");
    //   div.innerHTML = htmlData;
    //   let imgElement = div.getElementsByTagName("img");
    //   this.modelLoading = true;
    //   await Promise.all(
    //     await Array.from(imgElement).map(async (item) => {
    //       if (item.src && item.src.indexOf("data:image") != -1) {
    //         await this.submitImg(item.src);
    //       }
    //     })
    //   );
    //   this.modelLoading = false;
    // },
    async submitImg(src) {
      let file = base64ToFile(src);
      let formData = new FormData();
      formData.append("file", file);
      await sheetWorkImg(formData).then((res) => {
        let url = res.data.data.url;
        setTimeout(() => {
          this.editorData = this.editorData.replaceAll(src, url);
        }, 500);
      });
    },
    lintFrom() {
      if (this.questionData.children && this.questionData.children.length > 0) {
        this.questionData.children = this.questionData.children.map((index) => {
          if (this.indexShowCnt.webId == index.webId) {
            Object.assign(index, this.indexShowCnt);
          }
          return index;
        });
      }
      this.questionData.originalQuestion = this.subQuestion.originalQuestion;
      this.questionData.answer = this.subQuestion.answer;
      this.questionData.analysis = this.subQuestion.analysis;
      let data = JSON.parse(JSON.stringify(this.questionData));
      if (data.hwQuestionType == 1 || data.hwQuestionType == 3) {
        data.answer = [...new Set(data.answer.sort())].join("");
      }
      if (data.children && data.children.length > 0) {
        data.children = data.children.map((item) => {
          item.difficulty == data.difficulty;
          item.analysis == data.analysis;
          if (item.hwQuestionType == 1 || item.hwQuestionType == 3) {
            item.answer = [...new Set(item.answer.sort())].join("");
          }
          return item;
        });
      }

      this.submit(data);
    },
    submit(data) {
      data.originalQuestion = data.originalQuestion.replaceAll(
        "&nbsp;",
        "\x26#12288;"
      );
      data.originalQuestion = data.originalQuestion.replaceAll(
        "　",
        "\x26#12288;"
      );

      let addType = [];
      let addAnswerType = [];
      if (!data.originalQuestion) {
        addType.push(data.questionNum);
      }
      if (data.hwQuestionType == 1 || data.hwQuestionType == 3) {
        if (data.answer.length == 0) {
          addAnswerType.push(data.questionNum);
        }
      }
      data.isMerge = this.isMerge;
      if (data.children && data.children.length > 0) {
        data.children = data.children.map((item) => {
          item.isMerge = this.isMerge;
          if (item.hwQuestionType == 1 || item.hwQuestionType == 3) {
            if (item.answer.length == 0) {
              addAnswerType.push(item.questionFNum + "-" + item.questionNum);
            }
          } else {
            item.answer = item.answer.replaceAll("&nbsp;", "\x26#12288;");
            item.originalQuestion = item.originalQuestion.replaceAll(
              "&nbsp;",
              "\x26#12288;"
            );
            if (!item.originalQuestion) {
              addType.push(item.questionFNum + "-" + item.questionNum);
            }
            // if (!item.answer) {
            //   addAnswerType.push(item.questionFNum + "-" + item.questionNum);
            // }
          }
          return item;
        });
      }

      if (addType.length > 0) {
        this.$message({
          showClose: true,
          message: addType.join(",") + "题干为空，请填写后提交",
          type: "warning",
        });
        return;
      }
      if (addAnswerType.length > 0) {
        this.$message({
          showClose: true,
          message: addAnswerType.join(",") + "题答案为空，请填写后提交",
          type: "warning",
        });
        return;
      }

      this.btnLoading = true;

      Object.assign(data, this.subData);
      data.knowledgeIds = data.knowledgeIds.join("#");
      data.knowledgeNames = data.knowledgeNames.join("#");

      data.question = data.originalQuestion;
      delete data.originalQuestion;

      if (data.children && data.children.length > 0) {
        data.children = data.children.map((item) => {
          item.knowledgeIds = data.knowledgeIds;
          item.knowledgeNames = data.knowledgeNames;
          item.questionNum = item.questionFNum + `（${item.questionNum}）`;
          item.question = item.originalQuestion;
          delete item.originalQuestion;
          return item;
        });
      }
      // console.log(data);

      updateQuestion(data)
        .then((res) => {
          // editData start
          // 成功后处理数据  先找到 能处理的组 groupKey
          // 在组中过滤掉试题 如果是材料题  parentMaterialId 也过滤掉
          // editData end
          // 后台返回的数据  提出想要数组
          // 然后根据 questionPriority 去插入数据
          // 如果修改为材料题  就提选出数组  如果不是的话 就插入一个
          this.btnLoading = false;
          let { groupList } = res.data.data;
          this.editQuestionItem(groupList, data);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    /**
     *
     * @param questionList 新的试题列表1
     * @param data 修改后的试题
     */
    editQuestionItem(newQuestionList) {
      // this.editData; 弹框弹起来的试题
      // 未更新前的试题列表
      let questionList = JSON.parse(JSON.stringify(this.GET_HW_QUESTION_LIST));
      // 根据序号获取出这次更新的所有试题
      let questionPriority = this.editData.questionPriority;
      newQuestionList = newQuestionList.map((item) => item.questionList);
      newQuestionList = newQuestionList.flat();
      let array = newQuestionList.filter(
        (item) => item.questionPriority == questionPriority
      );
      let oldIndex = 0;
      let oldGroupKey = this.editData.groupKey;
      // 先找出之前试题所在位置
      questionList.map((item) => {
        item.questionList.map((bItem, bIndex) => {
          if (
            bItem.questionNum == this.editData.questionNum &&
            !bItem.questionFNum
          ) {
            oldIndex = bIndex;
          }
        });
      });
      //然后过滤掉之前试题
      questionList = questionList.map((item) => {
        item.questionList = item.questionList.filter(
          (bItem) =>
            (bItem.questionNum != this.editData.questionNum &&
              !bItem.questionFNum) ||
            bItem.questionFNum
        );
        if (this.editData.materialId) {
          item.questionList = item.questionList.filter(
            (bItem) => bItem.questionFNum != this.editData.questionNum
          );
        }
        return item;
      });
      // 再将新剔选出来的试题插入到oldIndex, oldGroupKey 指定位置
      // console.log(questionList);
      questionList = questionList.map((item) => {
        if (item.groupKey == oldGroupKey) {
          item.questionList.splice(oldIndex, 0, ...array);
        }
        return item;
      });

      this.dialogVisible = false;
      this.$parent.subNewQuestion(questionList);
    },
    difficultyFn(data) {
      let difficulty = data.difficulty;
      // console.log(difficulty);
      if (difficulty <= 1 && difficulty >= 0.9) {
        data.difficulty = 0.9;
      } else if (difficulty < 0.9 && difficulty >= 0.7) {
        data.difficulty = 0.7;
      } else if (difficulty < 0.7 && difficulty >= 0.5) {
        data.difficulty = 0.5;
      } else if (difficulty < 0.5 && difficulty >= 0.3) {
        data.difficulty = 0.3;
      } else if (difficulty < 0.3 && difficulty > 0) {
        data.difficulty = 0.1;
      } else if (difficulty == 0) {
        data.difficulty = "";
      }
      return data;
    },

    async getQuestion(v) {
      await getInfo({
        uuid: this.$route.query.uuid,
      }).then(async (res) => {
        let questionTypeList = res.data.data.groupList;
        let groupKey = 0;
        res.data.data.groupList.map((item) => {
          if (!item.questionList) return;
          item.questionList.map((index) => {
            if (index.questionId == v.questionId) {
              groupKey = item.groupKey;
            }
          });
        });
        questionTypeList = questionTypeList.flatMap(
          (item) => item.questionList
        );

        let indexQuestion = questionTypeList.filter(
          (item) => item.questionNum == v.questionNum && !item.questionFNum
        )[0];

        let data = {
          originalQuestion: indexQuestion.questionContent,
          knowledgeIds: indexQuestion.knowledgeIds || "",
          difficulty: indexQuestion.difficulty || 0.1,
          optionCount: indexQuestion.optionCount,
          hwQuestionType: indexQuestion.hwQuestionType,
          knowledgeNames: indexQuestion.knowledgeNames || "",
          answer: indexQuestion.questionAnswer || "",
          questionPriority: indexQuestion.questionPriority,
          questionNum: indexQuestion.questionNum,
          materialId: indexQuestion.materialId,
          questionId: indexQuestion.questionId,
          score: indexQuestion.questionScore,
          hwQuestionId: indexQuestion.hwQuestionId,
          groupKey: groupKey,
          sheetUuid: indexQuestion.sheetUuid,
          id: indexQuestion.id,
          analysis: indexQuestion.analysis,
          questionKey: indexQuestion.questionKey,
          children: [],
        };
        this.isMerge = indexQuestion.isMerge || 0;
        data = this.difficultyFn(data);

        if (v.children && v.children.length > 0) {
          v.children.map((item, index) => {
            let indexObj = questionTypeList.filter(
              (Bitem) =>
                Bitem.questionNum == item.questionNum &&
                Bitem.questionFNum == item.questionFNum
            )[0];
            // console.log(index);
            // console.log(indexObj);
            // console.log(item);
            let copyData = {
              originalQuestion: indexObj.questionContent,
              optionCount: indexObj.optionCount,
              hwQuestionType: indexObj.hwQuestionType,
              answer: indexObj.questionAnswer || "",
              questionId: indexObj.questionId,
              questionNum: indexObj.questionNum,
              score: indexObj.questionScore,
              questionFNum: indexQuestion.questionNum,
              parentMaterialId: indexQuestion.materialId,
              questionPriority: indexQuestion.questionPriority,
              knowledgeNames: indexObj.knowledgeNames || "",
              knowledgeIds: indexObj.knowledgeIds || "",
              hwQuestionId: indexObj.hwQuestionId,
              analysis: indexObj.analysis,
              isMerge: indexObj.isMerge,
              questionKey: indexObj.questionKey,
              id: indexObj.id,
              webId: index + 1,
            };

            data.children.push(copyData);
          });
        }
        if (!data.id) {
          data.id = data.children[0].id;
        }
        if (data.knowledgeIds) {
          this.subData.knowledgeIds = data.knowledgeIds.split("#");
          this.subData.knowledgeNames = data.knowledgeNames.split("#");
        } else {
          this.subData.knowledgeIds = [];
          this.subData.knowledgeNames = [];
        }
        if (data.children && data.children.length > 0) {
          this.isMerge = data.children[0].isMerge;

          let arrId = data.children.map((item) => {
            if (item.knowledgeIds) {
              return item.knowledgeIds.split("#");
            } else {
              return "";
            }
          });
          arrId = arrId.flat();
          arrId = arrId.filter((item) => item);
          arrId = [...new Set(arrId)];
          let arrName = data.children.map((item) => {
            if (item.knowledgeNames) {
              return item.knowledgeNames.split("#");
            } else {
              return "";
            }
          });
          arrName = arrName.flat();
          arrName = [...new Set(arrName)];
          arrName = arrName.filter((item) => item);
          this.subData.knowledgeIds = arrId;
          this.subData.knowledgeNames = arrName;
        }
        this.subData.hwQuestionType = data.hwQuestionType;
        this.subData.difficulty = data.difficulty || 0.1;
        this.subData = JSON.parse(JSON.stringify(this.subData));
        this.editData = data;
      });
    },
    async init(v) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.questionBox.scrollTop = 0;
      });
      this.viewLoading = true;
      await this.getQuestion(v);
      let copyData = JSON.parse(JSON.stringify(this.editData));
      // console.log(copyData);
      this.settingObj = getStore({ name: "settingObj" });

      // 拷贝原数据  然后替换dom节点
      this.indexQuestion = {};

      this.$nextTick(() => {
        this.oldData = JSON.parse(JSON.stringify(copyData));
        let data = JSON.parse(JSON.stringify(copyData));

        data.optionCount = data.optionCount || 4;
        data.optionCount = Number(data.optionCount);
        if (data.hwQuestionType == 1 || data.hwQuestionType == 3) {
          data.answer = data.answer.split("");
          if (data.abnormal == 1) {
            data.answer = [];
          }
        }
        if (data.children && data.children.length > 0) {
          data.children = data.children.map((item) => {
            if (item.hwQuestionType == 1 || item.hwQuestionType == 3) {
              item.answer = item.answer.split("");
              item.optionCount = item.optionCount || 4;
              item.optionCount = Number(item.optionCount);
              if (item.abnormal == 1) {
                item.answer = [];
              }
            }
            return item;
          });
          this.indexQuestion = data.children[0];
        }
        this.questionData = data;

        this.checkLoading = true;
        setTimeout(() => {
          this.ckKey = new Date().getTime();
          this.ckKeyOne = new Date().getTime();
          this.ckKeyTwo = new Date().getTime();
        }, 500);
        this.viewLoading = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sub-title {
  margin-bottom: 8px;

  span {
    color: #f56c6c;
  }
}
.know-box {
  display: flex;
  align-items: center;

  .label {
    margin-bottom: 8px;
    flex-shrink: 0;
  }
  .el-button {
    margin-bottom: 8px;
    border: none;
  }
  .know-item {
    display: flex;
    flex-wrap: wrap;
    .know-item-text {
      margin-bottom: 8px;
      margin-right: 8px;
      padding: 4px 8px;
      background: #f5f6f8;
      border-radius: 4px;
      // line-height: 1;
      i {
        cursor: pointer;
      }
    }
  }
}
.edit-question-model {
  ::v-deep {
    .el-dialog {
      margin-top: 20px !important;
      margin: 0 auto;
    }
  }
}
.question-check {
  margin-bottom: 12px;
}
.editQuestion {
  .answer-box {
    width: 100%;
  }
  .question-edit-box {
    height: calc(100vh - 200px);
  }
  .el-input-number {
    width: 130px;
  }
  .option-num-box {
    margin: 18px 0;
    display: flex;
    align-items: center;
    .sub-title {
      margin-bottom: 0;
      margin-right: 8px;
    }
  }
  .option-item-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 16px;
    .btn-item {
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
      background: #f5f7fa;
      border-radius: 4px;
      border: 1px solid #d5d6db;
      color: #000000;
      margin-bottom: 8px;
      margin-right: 8px;
    }
    .sub-title {
      // margin-bottom: 0;
      margin-right: 8px;
    }
    .el-icon-error {
      color: #c4c4c4;
      position: absolute;
      right: -11px;
      top: -8px;
      font-size: 16px;
      opacity: 0;
    }
    .el-button {
      margin-bottom: 8px;
    }
    .option-item {
      min-width: 46px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d5d6db;
      padding: 0 18px;
      color: #3e4551;
      margin-right: 8px;
      text-align: center;
      line-height: 29px;
      cursor: pointer;
      user-select: none;
      margin-bottom: 8px;
      position: relative;
      &:hover {
        .el-icon-error {
          opacity: 1;
        }
      }
    }
    .option-item-on {
      background: #2474ed;
      color: #ffffff;
    }
  }
  // .option-item-box{}
}
</style>
<style lang="scss">
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.editQuestion {
  .quizPutTag {
    border-bottom: 1px solid #333333;
  }

  /*加粗*/
  .mathjye-bold {
    font-weight: 800;
  }

  /*下划线*/
  .mathjye-underline {
    padding-bottom: 0px;
    border-bottom: 1px solid #000;
  }

  /*删除线*/
  .mathjye-del {
    text-decoration: line-through;
  }

  /*斜体*/
  .mathjye-italic {
    font-style: italic;
  }

  /*点线*/
  .mathjye-underpline {
    border-bottom: 2px dotted #000;
    padding-bottom: 3px;
  }

  // 菁优网格式修改
  .MathJye {
    border: 0 none;
    direction: ltr;
    display: inline-block;
    float: none;
    font-family: "JyeooHai-letter-Italic", "JyeooHai-letter",
      "JyeooHai-Main-Regular", "Times New Roman", "宋体";
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 1px;
    line-height: normal;
    margin: 0;
    padding: 0;
    text-align: left;
    text-indent: 0;
    text-transform: none;
    white-space: nowrap;
    word-spacing: normal;
    word-wrap: normal;
    -webkit-text-size-adjust: none;
    vertical-align: middle;
  }

  .MathJye table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    line-height: normal;
    font-size: inherit;
    *font-size: 100%;
    /* _font-size: 100%; */
    font-style: normal;
    font-weight: normal;
    border: 0;
    float: none;
    display: inline-block;
    *display: inline;
    zoom: 0;
  }

  .MathJye table td {
    padding: 0;
    font-size: inherit;
    line-height: normal;
    white-space: nowrap;
    border: 0 none;
    width: auto;
    /* _height: auto; */
  }

  .MathJye table th {
    border: none !important;
    /* _height: auto; */
  }

  .MathJye_mi {
    font-style: normal;
  }
}
</style>
